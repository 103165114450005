import * as React from "react";
import Layout from "../components/Layout";
import { useStaticQuery, graphql } from "gatsby";
import Typewriter from "typewriter-effect";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      homeCover: file(relativePath: { eq: "cover.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <Layout
      imageDesktop={data.homeCover.childImageSharp.fluid}
      imageMobile={data.homeCover.childImageSharp.fluid}
    >
      <div className="flex fixed w-full top-1/3 place-content-center">
        <div className="flex text-2xl text-sky-offwhite w-1/2 place-content-center">
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .typeString(
                  "\"You’ve baked a really lovely cake, but then you’ve used dog shit for frosting.\" - Steve Jobs"
                )
                .callFunction(() => {
                  console.log("String typed out!");
                })
                .pauseFor(2500)
                .start();
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
